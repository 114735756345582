/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .h1insuranceApiPlatform{
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 76px;
    line-height: 100%;
    color: #06076a;
  }
  a.btn.buttonOne.aProduct {
    margin-top: 14px;
  }
  .wrapRowinsuranceApiPlatform1 .pProducts,
  .wrapRowProducts1 .pProducts{
    padding: 0px 0px 0px 0px;
  }
  .h1insuranceApiPlatform span{
    color: #65A5FF;
  }
  .width80{
    width: 80%;
  }
  .wrapRowinsuranceApiPlatform1{
    margin: 70px 0px 170px 0px;
  }
  .wrapRowinsuranceApiPlatform2{
    margin: 170px 0px 134px 0px;
  }
  .wrapRowinsuranceApiPlatform3{
    margin: 134px 0px 79px 0px;
  }
  .wrapRowinsuranceApiPlatform4{
    margin: 79px 0px 189px 0px;
  }
  .wrapRowinsuranceApiPlatform5{
    margin: 189px 0px 169px 0px;
  }
  .h2insuranceApiPlatform1{
    margin: 40px 0px 20px 0px;
  }
  .h2insuranceApiPlatform2{
    margin: 84px 0px 0px 0px;
  }
  .h2insuranceApiPlatform3{
    margin: 98px 0px 0px 0px;
  }
  .h2insuranceApiPlatform4{
    margin: 72px 0px 0px 0px;
  }
  .h2insuranceApiPlatform5{
    margin: 80px 0px 0px 0px;
  }
  .h2Products {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    color: #35449f;
  }
  .pProducts {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: #858591;
  }
  .wrapRowProducts1 {
    margin: 87px 0px 186px 0px;
  }
  .wrapRowProducts2 {
    margin: 186px 0px 69px 0px;
  }
  .wrapRowProducts2 h2 {
    margin: 60px 0px 0px 0px;
  }
  .imgProduvts2top {
    display: block;
  }
  .imgProduvts2bottom {
    display: none;
  }
  .imfageTop {
    display: block;
  }
  .imfageBottom {
    display: none;
  }
  .imgProduvts4top {
    display: block;
  }
  .imgProduvts4bottom {
    display: none;
  }
  .wrapRowProducts3 h2 {
    margin: 150px 0px 0px 0px;
  }
  .wrapRowProducts4 h2 {
    margin: 84px 0px 0px 0px;
  }
  .wrapRowProducts3 {
    margin: 69px 0px 179px 0px;
  }
  .wrapRowProducts4 {
    margin: 179px 0px 196px 0px;
  }
  .wrapRowProducts5 {
    margin: 196px 0px 155px 0px;
  }
  .wrapRowProducts6 {
    margin: 155px 0px 74px 0px;
    background: linear-gradient(
      163.64deg,
      rgba(243, 248, 255, 0.5) 35.15%,
      rgba(223, 232, 255, 0.5) 85.56%
    );
    border-radius: 10px;
    padding: 60px 60px 60px 60px;
  }
  .imgPineappleProduct {
    width: 139px;
    height: 158px;
    position: absolute;
    top: -155px;
    left: 31px;
  }
  .wrapRowProducts6 .buttonProduct {
    margin: 35px 0px 0px 0px;
    float: right;
  }
  .wrapRowProducts1 h2 {
    margin: 100px 0px 0px 0px;
  }
  .wrapRowProducts5 h2 {
    margin: 100px 0px 0px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .imfageTop {
    display: none;
  }
  .imfageBottom {
    display: block;
  }
  .imgProduvts2top {
    display: none;
  }
  .imgProduvts2bottom {
    display: block;
  }
  .imgProduvts4top {
    display: none;
  }
  .imgProduvts4bottom {
    display: block;
  }
  .wrapRowProducts2 h2 {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowProducts3 h2 {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowProducts4 h2 {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowProducts1 h2 {
    margin: 0px 0px 0px 0px;
  }
  .wrapRowProducts5 h2 {
    margin: 0px 0px 0px 0px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .h2Products{
    text-align: center;
  }
  .pProducts{
    text-align: center;
  }
  .wrapRowProducts6 .buttonProduct {
    margin: 35px auto 0px auto;
    float: none;
  }
  .h1insuranceApiPlatform {
    text-align: center;
  }
  .pProducts {
    width: 100%;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .h1insuranceApiPlatform {
    font-size: 54px;
    text-align: center;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
}
