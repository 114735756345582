@media only screen and (max-width: 25000px) {
  .wrapCookies {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 60px 115px;
    gap: 14px 0px;
    grid-template-areas:
        "leftTop rightTop"
        "leftBottom rightBottom";
    position: fixed;
    bottom: 0;
    background: #fffffff7;
    z-index: 100;
    box-shadow: 0px -2px 0px #F3F1F1;
  }
  .wrapCookiesBlockText { grid-area: leftTop; }
  .mainCookiesText { grid-area: leftBottom; }
  .imgCloseCokies { 
    grid-area: rightTop;
    display: grid;
    justify-content: end;
    margin: 20px 35px 0px 0px;
  }
  .imgCloseCokies img{ 
    width: 14px;
    height: 14px;
  }
  .imgCloseCokies img:hover{ 
    cursor: pointer;
  }
  .wrapButtonCookies { 
    grid-area: rightBottom;
    display: grid;
    align-items: end;
    justify-content: end;
  }
  .wrapCookiesBlockText{
    display: flex;
    flex-direction: row;
    height: 100%;
    font-size: 24px;
    line-height: 140%;
    color: #06076A;
    margin: 20px 0px 0px 30px;
  }
  .wrapCookiesBlockText2 span img{
    width: 34px;
    height: 34px;
  }
  .wrapCookiesBlockText img{
    margin-left: 14px;
  }
  .mainCookiesText{
    font-size: 16px;
    line-height: 150%;
    color: #858591;
    margin: 0px 0px 65px 30px;
  }
  .wrapCookies .btn.buttonOne.buttonCookies{
    margin: 0px 30px 26px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .wrapButtonCookies .btn.buttonOne.buttonCookies{
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .wrapCookies {
    grid-template-columns: 90% 10%;
    grid-template-rows: 90px 140px 100px;
    grid-template-areas:
        "leftTop rightTop"
        "leftBottom ."
        "rightBottom .";
  }
  .wrapCookiesBlockText{
    justify-content: center;
  }
  .mainCookiesText {
    margin: 0px 30px 0px 30px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .wrapButtonCookies {
    align-items: center;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .wrapCookies .btn.buttonOne.buttonCookies {
    //margin: 0px 0px 26px 0px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .wrapCookies {
    grid-template-rows: 90px auto 100px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  
}
