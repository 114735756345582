a.divBlogsItemMoreArrow img {
  width: 20px;
  align-items: center;
  display: grid;
}
a.divBlogsItemMoreRead {
  align-items: center;
  display: grid;
}
.wrapDivBlogsItemMore{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, auto);
}
.divBlogsItemMoreRead{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.divBlogsItemMoreArrow{
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-items: center;
  display: grid;
}
.wrapBlogsItems .wrapBlogsItem {
  min-height: 656px;
}
.wrapBlogsItem  {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, auto);
}
.wrapBlogsItemImg{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.wrapBlogsItemH2{
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}
.wrapBlogsItemP{
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}
.wrapInfoDateMore{
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}
span.blogsDate {
  margin: 0 0 0 40px;
  align-items: center;
  display: grid;
}
.wrapInfoDateMore {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}
a.wrapBlogsItemH2 h2 {
  text-align: center;
}
.wrapInfoDateMore {
  padding: 0 0 30px 0px;
}
.blogsDate,
.blogsItemMore {
  width: 50%;
  height: 100%;
  align-items: center;
}
.blogsDate {
  justify-content: flex-start;
}
.blogsItemMore {
  justify-content: flex-end;
}
.divBlogsItemMore {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  height: 100%;
  padding: 0 44px 0 0;
}
// .wrapBlogsItem span.blogsItemMore {
//   background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2LjAxIDExSDR2MmgxMi4wMXYzTDIwIDEybC0zLjk5LTR2M3oiIGZpbGw9IiM4NTg1OTEiLz48L3N2Zz4=) no-repeat;
//   background-position: 66% 100%;
//   padding: 0 66px 0 0;
// }
button.buttonOne.buttonOneForm.btn.btn-primary {
  border-color: #ff6565;
  background: #ff6565;
  box-shadow: none;
  margin: 5px auto 24px auto;
}
button.buttonOne.buttonOneForm.btn.btn-primary:hover, .btn.buttonOne:hover{
  background: #FF4B4B;
  box-shadow: 0px 0px 4px #F55F44;
}
span.blogsItemMore a {
  color: #858591;
  text-decoration: none;
}
// .divBlogsItemMore {
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: center;
//   align-items: flex-end;
//   -webkit-justify-content: center;
//   justify-content: flex-end;
//   height: 100%;
//   padding: 0 27px 0 0;
// }
.divBlogsItemMore div:hover {
  color: #3079ed;
  animation-name: tracking-in-contract-bck;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}


@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}




@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}



.formSubscriber input{
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px 26px 11px 26px;
}
.formSubscriber .form-group{
  margin: 25px 0px 25px 0px;
}
.h1Blogs {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #06076a;
  margin: 80px 0px 40px 0px;
}
.wrapRowItemBlogs {
  margin: 40px 0px 80px 0px;
}
.wrapBlogsItem {
  background: #ffffff;
  box-shadow: 10px 10px 100px rgba(229, 229, 229, 0.5),
  0px 0px 24px rgba(122, 121, 156, 0.06);
  border-radius: 10px;
  margin: 35px 20px 35px 20px;
  min-height: 610px;
  position: relative;
}
.imgFormSubsciberFlover {
  width: 202px;
  height: 138px;
  position: absolute;
  bottom: -100px;
  left: -156px;
}
.wrapBlogsItem img {
  width: 100%;
}
.wrapBlogsItem h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #35449f;
  margin: 33px 40px 20px 40px;
}
.wrapBlogsItem p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #858591;
  margin: 20px 40px 52px 40px;
}
.wrapBlogsItem span.blogsDate {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #858591;
  // float: left;
  // display: block;
  // margin: 0px 0px 0px 40px;
}
// .wrapBlogsItem span.blogsItemMore {
// background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNi4wMSAxMUg0VjEzSDE2LjAxVjE2TDIwIDEyTDE2LjAxIDhWMTFaIiBmaWxsPSIjODU4NTkxIi8+DQo8L3N2Zz4NCg==")
//   no-repeat;
// background-position: 66% 100%;
// padding: 0px 50px 0px 0px;
// float: right;
// display: block;
// margin: 0px 40px 0px 0px;
// }
.wrapInfoDateMore {
  // display: inline-block;
  // width: 100%;
  // margin: 0px 0px 30px 0;
  // position: absolute;
  // bottom: 0px;
}
.h1Blog {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-transform: uppercase;
  color: #35449f;
  margin: 113px 0px 0px 0px;
}
.pDataBlog {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #858591;
  margin: 16px 0px 34px 0px;
}
.imgItMattersToUsWhatResultYouShow,
.imgHowToRemainTeamWithRemoteWork,
.imgHowSagaxCelebratedItsFirstBirthday {
  width: 100%;
  margin: 30px 0px 30px 0px;
}
.pTitleBlog {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #06076a;
  margin: 0px 0px 30px 0px;
}
.pMainTextBlog {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #525252;
}
.wrapBlogItem {
  color: #858591 !important;
  width: 100%;
  display: block;
  margin: 0px auto;
}
.tdTableCenter {
  vertical-align: middle;
  align-content: center;
}
.tableCenter {
  width: 100%;
  height: 100%;
}



/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {

}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  // .wrapBlogsItem span.blogsItemMore {
  //   background-position: 76% 100%;
  //   padding: 0 50px 0 0;
  // }
  .wrapBlogsItems .wrapBlogsItem {
    min-height: 676px;
  }
  .wrapBlogsItem {
    min-height: 628px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .wrapBlogsItems .wrapBlogsItem {
    min-height: auto;
  }
  .wrapFormSubscriber {
    width: 100%;
    margin: 80px 0 200px 0;
    padding: 52px 0px 56px 0px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {

  // .wrapBlogsItem span.blogsItemMore {
  //   background-position: 76% 100%;
  //   padding: 0 50px 0 0;
  // }
  .divBlogsItemMore {
    padding: 0 14px 0 0;
  }

  .wrapBlogsItem span.blogsDate {
    font-size: 10px;
    margin: 0 0 0 25px;
    line-height: 26px;
  }
  .blogsItemMore .divBlogsItemMore a{
    font-size: 11px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .wrapBlogsItem {
    margin: 0;
  }
  .wrapBlogsItem h2 {
    font-size: 18px;
    text-align: center;
    margin: 33px 20px 20px 20px;
  }
  .wrapBlogsItem {
    min-height: auto;
    margin: 35px 0px 35px 0px;
    padding: 0 0 20px 0;
  }
  .wrapBlogsItem p {
    margin: 20px 20px 52px 20px;
    text-align: center;
  }
  .wrapBlogsItem span.blogsDate {
    font-size: 10px;
    margin: 0 0 0 25px;
    line-height: 26px;
  }
  .blogsItemMore .divBlogsItemMore a{
    font-size: 11px;
  }
  .wrapFormSubscriber {
    margin: 20px 0 20px 0;
    padding: 0;
  }
  .h2Form {
    margin: 30px 0px 70px 0px;
  }
  .imgFormSubsciberFlover {
    display: none;
  }
}


/* For design for blog */
.wrapTextFlexBlock{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
}
.textFlexBlockImg1{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  height: auto;
  padding: 60px;
}
.textFlexBlockText1{
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-items: center;
  display: flex;
  height: 100%;
}
.textFlexBlockText2{
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  align-items: center;
  display: flex;
  height: 100%;
}
.textFlexBlockImg2{
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  width: 100%;
  height: auto;
  padding: 60px;
}
@media only screen and (max-width: 992px) {
  .textFlexBlockImg1{
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    width: 100%;
    height: auto;
    padding: 5%;
  }
  .textFlexBlockText1{
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    align-items: center;
    display: flex;
    height: 100%;
  }
  .textFlexBlockText2{
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    align-items: center;
    display: flex;
    height: 100%;
  }
  .textFlexBlockImg2{
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    width: 100%;
    height: auto;
    padding: 5%;
  }
}

/* For design for blog */