.nav-item:focus,
.nav-link:focus,
.nav-link:hover{
  outline: none !important;
}
.header {
  line-height: 35px;
  height: 70px;
  box-shadow: 0px 1px 0px #f3f1f1;
  width: 100%;
}
.header-navbar {
  width: 100%;
}
.topLogo {
  width: 100px;
}
.dropdown-menu{
  border: 1px solid #E6E6E6;
  box-shadow: 5px 5px 10px rgba(229, 229, 229, 0.5);
  border-radius: 3px;
  background: #fff !important;
}
.header .dropdown-item:hover {
  text-shadow: 0 0 black;
  color: #ff6565;
}
.dropdown-toggle:hover::after {
  color: #ff6565;
}
.header .navbar-expand-lg .navbar-nav .nav-link:hover {
  // padding-left: 0.6px;
  // padding-right: 0.7px;
}
#basic-nav-dropdown-case-studies,
#basic-nav-dropdown-products,
.header .navLink {
  position: relative;
  color: var(--text-color-grey);
  font-size: 0.9375rem;
}
.header .navbar-expand-lg .navbar-nav .nav-link {
  margin-right: 20px;
  margin-left: 20px;
}
.header .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0rem;
  padding-left: 0rem;
}
.dropdown-toggle.nav-link:hover,
a.navLink.nav-link:hover {
  text-shadow: 0 0 black;
}
#basic-nav-dropdown-case-studies:before ,
#basic-nav-dropdown-products:before ,
.header .nav-link:before ,
.header .navLink:before {
  content: "";
  position: absolute;
  top: 45px;
  left: 0px;
  width: 0;
  height: 2px;
  background: #ff6565;
  border-radius: 5px;
  transition: all 1s ease;
  z-index: 10;
}

#basic-nav-dropdown-case-studies:hover:before ,
#basic-nav-dropdown-products:hover:before ,
.header .navLink:hover:before {
  width: 100%;
}
.header .navbar-right {
  float: right;
}
.header div#basic-navbar-nav {
  background: #fff;
  z-index: 20;
}
.header .dropdown-item{
  background-color: #ffffff;
}
.dropdown-menu{
  min-width: 4rem;
}
/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .header .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
  .header .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .header .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .header .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .header .navbar-nav {
    margin: 0 0 0 0;
    width: 100%;
  }
  #basic-nav-dropdown-case-studies, #basic-nav-dropdown-products, .header .navLink {
    position: relative;
    color: var(--text-color-grey);
    font-size: 0.9375rem;
    text-align: center;
  }
  .dropdown-item {
    text-align: center;
  }
  .dropdown-menu {
    border: none;
    box-shadow: none;
    border-radius: 0px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
}
