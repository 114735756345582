body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .navbar-nav {
    margin: 0 0 0 auto;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
}
