.pBoldFooter a,
.pBoldFooter{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.pNormalFooter{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
}
.wrap-footer a {
  background: #050650;
  color: #ffffff;
}
.divMenuFooter {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}
body {
  /* For test this script on blank page */
  /* min-height: 3000px; */
}
#back-top {
  position: fixed;
  top: 20px;
  left: 20px;
}

#back-top a {
  display: block;
  text-align: center;
  font: 12px Arial, Verdana, Tahoma, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #333;
}

#back-top a:hover {
  color: #000;
}

#back-top span {
  display: none;
  width: 47px;
  height: 47px;
  display: block;
  margin-bottom: 7px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAMAAAEz/lMIAAAABGdBTUEAAYagMeiWXwAAAAlwSFlzAAAASAAAAEgARslrPgAAALdQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQEBAwMDBQUFBgYGCAgICQkJDQ0NExMTFxcXGRkZHh4eJycnLy8vOTk5PDw8QkJCRkZGd3d3uLi4wMDAwsLCysrK1tbW3Nzc3t7e4uLi5+fn6+vr7e3t9PT0+Pj4+vr6////tm20WgAAADx0Uk5TAAIDFBUpKiwtOT5FR1NUVmVmbG5vcnh6e31+gISHioyNj5KWmZueoqerrbCxxuHk5unt8PLz9fb4+/z+bLj0zgAAAAFiS0dEPKdqYc8AAAFrSURBVEjHtVSLVsIwDA0gMB8oG4hiBBQUEJ+g4ID8/3fZdgMa2kHrgXvO1vXmNs2StAB1RAwgRAkQD6mBiNRMksVkgM1bSSZqWFKyYLVc00g0te9UEEvfazWboOkgBxCknxEA3NpVGJr7II03E7HNbP2HEkvNgmYEWZOSvquIhwm1mItoRQkwA7rh68NqmMq4vw3DglLMjRUJb3GVadiOKrTzIauEXm6JIOJsFCga8pUm428qeUmfWxxdABSsO5+AQwIPxLepZeMfReo6Jt9TOe1u8/20Ok+cH66qRgOdf6MNRtw/8aL9mz9+3jhvr2Mhs+6yTxqMbCR9IlCu4X7Uy6n6FF1xJtS5KrqjmoMK+uAK7rz02WfST996v/fQd8aiYz7bjvruT9pjkwcH/fNM6+1pb49+MCeOuL9DP1qSicUwQ/8aK3D3Er8vu/6X6R3yc2z9gfvHtz/9+v/S+3z5nl91P1w7iGvqfvgDhj/+1ymZZVAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTUtMDMtMTZUMjA6NDE6MDkrMDM6MDB/ybotAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE1LTAzLTE2VDIwOjQxOjA5KzAzOjAwDpQCkQAAAABJRU5ErkJggg==")
    no-repeat center center;
  /* display: none; */
}

.dropdown-menu{
  background: transparent;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
  }
  .wrap-footer {
    padding: 70px 30px 40px 30px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 325px;
    font-size: 16px;
    line-height: 150%;
    margin: 19px 0 92px 0;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
  }
  .wrap-footer {
    padding: 70px 30px 40px 30px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 325px;
    font-size: 16px;
    line-height: 150%;
    margin: 19px 0 92px 0;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
  }
  .wrap-footer {
    padding: 70px 30px 40px 30px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 325px;
    font-size: 16px;
    line-height: 150%;
    margin: 19px 0 92px 0;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
  .wrap-footer {
    padding: 70px 30px 40px 30px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 349px;
    font-size: 16px;
    line-height: 150%;
    margin: 19px auto 50px auto;
    text-align: center;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
    display: block;
    margin: 0 auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
  .wrap-footer {
    padding: 70px 30px 40px 30px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 100%;
    font-size: 16px;
    line-height: 150%;
    margin: 19px auto 50px auto;
    text-align: center;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
    display: block;
    margin: 0 auto;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .divCopyFooter {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
  .wrap-footer {
    padding: 35px 0px 12px 0px;
    background: #050650;
    color: #ffffff;
  }
  .divFooterText {
    width: 100%;
    font-size: 16px;
    line-height: 150%;
    margin: 19px 0 42px 0;
    text-align: center;
  }
  .wrap-footer img.logoFooter {
    width: 144px;
    height: 29px;
    display: block;
    margin: 0 auto;
  }
}
