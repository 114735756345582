/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .wrapRowAPISalesTool1 {
    margin: 60px 0px 60px 0px;
  }
  .h2APISalesTool {
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 100%;
    color: #06076a;
    margin: 40px 0px 15px 0px;
  }
  .pAPISalesTool2margin{
    margin: 70px 0px 0px 0px;
  }
  .pAPISalesTool2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #858591;
  }
  .h2APISalesTool2 {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: left;
    text-transform: uppercase;
    color: #06076a;
  }
  .wrapRowAPISalesTool3 {
    margin: 60px 0px 100px 0px;
  }
  .imgApiSalesTool2 {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
  .wrapRowAPISalesTool4 {
    margin: 100px 0px 80px;
  }
  .imgApiSalesTool3 {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .imgApiSalesTool2Top {
    display: block;
  }
  .imgApiSalesTool2Bottom {
    display: none;
  }
  .wrapRowAPISalesTool4 p {
    margin: 70px 0px 0px 0px;
  }
  .wrapRowAPISalesTool5 {
    margin: 10px 0px 60px 0px
  }
  .wrapRowAPISalesTool6 {
    margin: 60px 0px 20px 0px;
  }
  .wrapRowAPISalesTool7 {
    margin: 20px 0px 100px 0px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
	.imgApiSalesTool2 {
    width: 54%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .imgApiSalesTool2 {
    width: 75%;
  }
  .h2Company ,
  .h2APISalesTool2,
  .h2APISalesTool {
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .imgApiSalesTool2Top {
    display: none;
  }
  .imgApiSalesTool2Bottom {
    display: block;
  }
  img.imgApiSalesTool1 {
    margin: 50px 0px 0px 0px;
  }
  .imgApiSalesTool2 {
    width: 53%;
    margin: 50px auto 0 auto;
  }
  .imgApiSalesTool3 {
    width: 65%;
    margin: 70px auto 0 auto;
  }
  img.imgApiSalesTool4 {
    margin: 70px 0px 0px 0px;
  }
  .h2Conclusion {
    text-align: center;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
  .imgApiSalesTool2 {
    width: 90%;
  }
  .imgApiSalesTool3 {
    width: 90%;
  }
}
