/* Large Devices, Wide Screens */
@media only screen and (max-width: 25000px) {
  .wrapSuccessfullyBG {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(46, 47, 54, 0.7);
    z-index: 250;
  }
  .wrapSuccessfully {
    margin: 150px auto 0px auto;
    width: 580px;
    height: 512px;
    display: block;
    background: #ffffff;
    border-radius: 10px;
    padding: 86px 70px 86px 70px;
  }
  .h2Successfully {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #35449f;
  }
  .pSuccessfully {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    color: #858591;
    margin: 0px 0px 68px 0px;
  }
  .imgSuccessfully {
    width: 188px;
    height: 171px;
    display: block;
    margin: 0px auto;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 2500px) {
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .wrapSuccessfully {
    margin: 150px auto 0px auto;
    width: 90%;
    display: block;
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 40px 40px 40px;
  }
  .h2Successfully {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #35449f;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 320px) {
}
